<template>
  <a-spin size="large" :spinning="loading">
    <div class="home">
      <div class="wrap-form">
        <a-form :form="form" class="form">
          <div class="subTitle">{{ $t("UserDetails") }}</div>
          <a-form-item :label="$t('FirstName')">
            <a-input
              size="large"
              class="input"
              autocomplete="off"
              v-decorator="[
                'firstName',
                {
                  initialValue: firstName,
                  rules: [
                    {
                      required: true,
                      validator: function (rule, value, callback) {
                        if (!value || !value.trim('')) {
                          callback('plase input first name ');
                          return;
                        }
                        callback();
                      },
                    },
                  ],
                },
              ]"
              placeholder="Please input first name"
            />
          </a-form-item>
          <a-form-item :label="$t('LastName')">
            <a-input
              size="large"
              class="input"
              type="text"
              autocomplete="off"
              v-decorator="[
                'lastName',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input last name',
                    },
                  ],
                },
              ]"
              placeholder="Please input last name"
            />
          </a-form-item>

          <a-form-item>
            <a-button
              class="m120"
              @click="saveHandler"
              size="large"
              type="primary"
              >{{ $t("SaveChanges") }}</a-button
            >
          </a-form-item>
        </a-form>
      </div>
    </div>
  </a-spin>
</template>
<script>
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      form: this.$form.createForm(this, { name: "dynamic_rule" }),
      loading: false,
    };
  },

  computed: {},
  i18n: {
    messages: {
      CN: {
        UserDetails: "个人信息",
        LastName: "姓",
        FirstName: "名",
        SaveChanges: "保存更改",
      },
      US: {
        UserDetails: "User Details and Payment Status",
        LastName: "Last Name",
        FirstName: "First Name",
        SaveChanges: "Save Changes",
      },
      AR: {
        UserDetails: "تفاصيل المستخدم وحالة الدفع",
        LastName: "اللقب",
        FirstName: "الاسم الأول",
        SaveChanges: "حفظ التغييرات",
      },
    },
  },
  created() {},
  methods: {
    saveHandler() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          console.log(values);
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.home {
  margin: 70px 30px 0 30px;
  padding: 0px 30px 20px 30px;
  margin-bottom: 20px;
  background: #fff;
  .wrap-form {
    padding-top: 26px;
    min-height: 700px;

    .form {
      width: 400px;
    }
    position: relative;

    .switchbox {
      margin-bottom: 30px;
      .switch {
        margin-right: 11px;
      }
    }
    .connect {
      border: #0e756a solid 1px;
      color: #0e756a;
    }
    .m120 {
      margin-right: 120px;
    }
    .subTitle {
      font-family: Arial, Arial;
      font-weight: bold;
      font-size: 18px;
      color: #000000;
      margin-bottom: 10px;
    }
    /deep/.ant-form-item {
      margin-bottom: 20px;
    }
    /deep/.ant-input::placeholder {
      color: #999999 !important;
      background: #dfdfdf;
    }
    /deep/.ant-input:placeholder-shown {
      text-overflow: ellipsis;
      background: #dfdfdf;
    }
    .input {
      width: 400px;
    }
    /deep/.ant-form-item label {
      font-size: 16px;
      font-weight: bold;
    }
  }
}
</style>
