var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-spin", { attrs: { size: "large", spinning: _vm.loading } }, [
    _c("div", { staticClass: "home" }, [
      _c(
        "div",
        { staticClass: "wrap-form" },
        [
          _c(
            "a-form",
            { staticClass: "form", attrs: { form: _vm.form } },
            [
              _c("div", { staticClass: "subTitle" }, [
                _vm._v(_vm._s(_vm.$t("UserDetails")))
              ]),
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t("FirstName") } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "firstName",
                          {
                            initialValue: _vm.firstName,
                            rules: [
                              {
                                required: true,
                                validator: function(rule, value, callback) {
                                  if (!value || !value.trim("")) {
                                    callback("plase input first name ")
                                    return
                                  }
                                  callback()
                                }
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n              'firstName',\n              {\n                initialValue: firstName,\n                rules: [\n                  {\n                    required: true,\n                    validator: function (rule, value, callback) {\n                      if (!value || !value.trim('')) {\n                        callback('plase input first name ');\n                        return;\n                      }\n                      callback();\n                    },\n                  },\n                ],\n              },\n            ]"
                      }
                    ],
                    staticClass: "input",
                    attrs: {
                      size: "large",
                      autocomplete: "off",
                      placeholder: "Please input first name"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t("LastName") } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "lastName",
                          {
                            rules: [
                              {
                                required: true,
                                message: "Please input last name"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n              'lastName',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Please input last name',\n                  },\n                ],\n              },\n            ]"
                      }
                    ],
                    staticClass: "input",
                    attrs: {
                      size: "large",
                      type: "text",
                      autocomplete: "off",
                      placeholder: "Please input last name"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "m120",
                      attrs: { size: "large", type: "primary" },
                      on: { click: _vm.saveHandler }
                    },
                    [_vm._v(_vm._s(_vm.$t("SaveChanges")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }